import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Nav from './Nav';
import Footer from './Footer';

import Homepage from './Homepage';
import Services from './Services';
import Contact from './Contact';

import Amplify from 'aws-amplify';

Amplify.configure({
    API: {
        endpoints: [
          {
            ...(process.env.REACT_APP_STAGE === 'dev') ? {
              name: "Contact",
              endpoint: "https://dev-api.dhmechanics.co.uk/contact",
              custom_header: async () => {
                return {
                  'x-api-key': 'dh-mechanics-dev-api-key'
                }
              }
            } : {
              name: "Contact",
              endpoint: "https://api.dhmechanics.co.uk/contact",
              custom_header: async () => {
                return {
                  'x-api-key': 'dh-mechanics-prod-api-key'
                }
              }
            }
          }
        ]
    }
});

function NoMatch() {
  return (
    <p>No Match!!</p>
  )
}

function App() {
  return (
    <BrowserRouter>
      <div>
        <Nav colour="black" />

        <Switch>
          <Route exact path="/">
            <Homepage />
          </Route>

          <Route exact path="/services">
            <Services />
          </Route>
          
          <Route exact path="/contact">
            <Contact />
          </Route>

          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
