import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import hero from './images/services-hero.avif';
import brakes from './images/brakes.webp';
import tyres from './images/tyres.jpeg';
import servicing from './images/servicing.jpeg';
import diagnostics from './images/diagnostics.webp';
import suspension from './images/suspension.avif';
import spanners from './images/spanners.avif';

function Services() {
  return (
    <div className="services">
      <Helmet>
        <title>DH Mechanics - Services</title>
      </Helmet>
      <section className="hero homepage-hero is-white is-medium is-quarterheight">
        <div className="hero-body" style={{ backgroundImage: `url(${hero})` }}>
        </div>
      </section>
      <div className="container">
        <section className="section">
          <h1 className="title is-2 has-text-centered mb-6">
            Services
          </h1>
          <div className="columns is-vcentered" id="brakes">
            <div className="column is-half">
              <div className="content">
                <h2 className="title is-3">Brakes</h2>
                <p>The braking system is among the most important components of your vehicle. It is essential to keep your brakes in good working order to provide reliable control over your vehicle and greatly reduce the risk of an accident.</p>
                <p>If your vehicle requires routine brake replacement, diagnosing or repair of damaged parts this can be provided.</p>
                <p>Some of our brake services include:</p>
                <ul>
                  <li>Brake pad &amp; disc replacements</li>
                  <li>Brake system bleeding</li>
                  <li>Brake line &amp; calliper replacements</li>
                  <li>Fitment of upgraded/aftermarket brake components</li>
                </ul>
                <Link className="button is-danger" to="/contact">Enquire now</Link>
              </div>
            </div>
            <div className="column is-half is-hidden-mobile">
              <img alt="brakes" src={brakes} />
            </div>
          </div>
          <div className="columns is-vcentered" id="tyres">
            <div className="column is-half">
              <img alt="tyres" src={tyres} />
            </div>
            <div className="column is-half">
              <div className="content">
                <h2 className="title is-3">Tyres</h2>
                <p>It is vital to ensure your vehicle's tyres are in good condition and meet the road regulations. Failure to replace unsuitable tyres can result in MOT failure, fines and points on your license.</p>
                <p>Tyres should be replaced if they are showing signs of ageing, damage such as cuts or bulges or if the tread depth is nearing the legal minimum of 1.6mm.</p>
                <p>We provide:</p>
                <ul>
                  <li>Supply and fitment of Part worn tyres</li>
                  <li>Supply and fitment of new tyres</li>
                  <li>Wheel balancing</li>
                  <li>Diagnosing/repair of TPMS (Tyre pressure monitoring system)</li>
                </ul>
                <Link className="button is-danger" to="/contact">Enquire now</Link>
              </div>
            </div>
          </div>
          <div className="columns is-vcentered" id="servicing">
            <div className="column is-half">
              <div className="content">
                <h2 className="title is-3">Servicing</h2>
                <p>Routinely servicing your vehicle can prolong its life, reduce the risk of breakdown and help to maintain its value...</p>
                <p>At DH Mechanics we can provide a vast selection of service options to keep your vehicle in optimum condition.</p>
                <p>Whether you require a simple oil change or a full service, we can offer our services at a realistic price point with your budget in mind.</p>
                <p>Some of our servicing includes:</p>
                <ul>
                  <li>General condition checks - Lights, bushings, suspension, tyres &amp; more</li>
                  <li>Filter replacements - Oil, air, fuel &amp; pollen filters</li>
                  <li>Sparkplug replacement</li>
                  <li>Fluid replacements - Oil, coolant, windscreen washer, brake and clutch fluids</li>
                </ul>
                <Link className="button is-danger" to="/contact">Enquire now</Link>
              </div>
            </div>
            <div className="column is-half">
              <img alt="servicing" src={servicing} />
            </div>
          </div>
          <div className="columns is-vcentered" id="diagnostics">
            <div className="column is-half is-hidden-mobile">
              <img alt="diagnostics" src={diagnostics} />
            </div>
            <div className="column is-half">
              <div className="content">
                <h2 className="title is-3">Diagnostics</h2>
                <p>If your vehicle is experiencing intermittent faults, showing warning lights on the dash or driving in limp mode, it will require a diagnostic test.</p>
                <p>These tests help to identify problems with your vehicles engine management system which can prevent it from operating how it should.</p>
                <p>At DH Mechanics we use some of the latest diagnostics tools and technology to ensure we're able to quickly and efficiently find faults.</p><p>This can include:</p>
                <ul>
                  <li>Electrical issues</li>
                  <li>ABS issues</li>
                  <li>Faulty sensors</li>
                  <li>Stored engine management codes</li>
                </ul>
                <Link className="button is-danger" to="/contact">Enquire now</Link>
              </div>
            </div>
          </div>
          <div className="columns is-vcentered" id="suspension">
            <div className="column is-half">
              <div className="content">
                <h2 className="title is-3">Suspension</h2>
                <p>The various suspension components on a vehicle work in conjunction to provide the driver with a controlled and comfortable driving experience.</p>
                <p>These components can wear over time which may introduce other issues such as instability when cornering, harsh/bumpy ride, knocking noises or uneven tyre wear.</p>
                <p>Since these issues can affect the safety of your vehicle, the components must be in good working order.</p>
                <p>We can provide:</p>
                <ul>
                  <li>Full suspension inspection</li>
                  <li>Replacement of worn or faulty components</li>
                  <li>Fitment of ball joints, drop links, top mounts, springs &amp; dampers</li>
                  <li>Installation of upgraded parts</li>
                </ul>
                <Link className="button is-danger" to="/contact">Enquire now</Link>
              </div>
            </div>
            <div className="column is-half">
              <img alt="suspension" src={suspension} />
            </div>
          </div>
          <div className="columns is-vcentered" id="else">
           <div className="column is-half is-hidden-mobile">
              <img alt="anything else" src={spanners} />
            </div>
            <div className="column is-half">
              <div className="content">
                <h2 className="title is-3">Anything else</h2>
                <p>At DH mechanics, we can provide other services that go beyond the usual repair and service procedures.</p>
                <p>If you have any questions about other miscellaneous jobs or services that we may be able to provide, please use the contact form to get in touch and we will reply to your queries as soon as possible <Link to='/contact'>here...</Link></p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Services;
