import { NavLink } from 'react-router-dom';

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="section">
          <div className="columns is-vcentered">
            <div className="column">
              <div className="content">
                <p>DH Mechanics &copy; { new Date().getFullYear() }</p>
              </div>
            </div>
            <div className="column">
              <nav className="navbar is-white">
                <div className="navbar-end">
                  <NavLink className="navbar-item" to="/">Home</NavLink>
                  <NavLink className="navbar-item" to="/services">Services</NavLink>
                  <NavLink className="navbar-item" to="/contact">Contact</NavLink>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
