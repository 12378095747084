import { useState } from 'react';
import { API } from 'aws-amplify';
import Recaptcha from "react-recaptcha";
import { useForm } from "react-hook-form";
import { Helmet } from 'react-helmet';
import hero from './images/contact-hero.webp';

function Contact() {
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [showCaptchaError, setShowCaptchaError] = useState(false);
  const { register, handleSubmit, formState: { errors }, reset } = useForm();

  const recaptchaCallback = (verified) => {
    if(verified) {
      setCaptchaVerified(true);
      setShowCaptchaError(false);
    }
  }

  const submitContact = async (data) => {
    const { name, email, phone, reg, message } = data;
    if(!captchaVerified) {
      setShowCaptchaError(true);
    } else {
      await API.post('Contact', '', {
        body: {
          name,
          email,
          phone,
          reg,
          message
        }
      }).then(() => {
        reset();
        setEmailSent(true);
      }).catch(() => {
        setEmailFailed(true);
      });
    }
  }

  const phoneNumber = '07793 011 683';

  return (
    <div>
      <Helmet>
        <title>DH Mechanics - Contact</title>
      </Helmet>

      <section className="hero homepage-hero is-white is-medium is-quarterheight">
        <div className="hero-body" style={{ backgroundImage: `url(${hero})` }}>
        </div>
      </section>
      <div className="container">
        <section className="section">
          <h1 className="title is-2 has-text-centered mb-6">
            Contact
          </h1>
          <div className='iframe-container mb-5'>
            <iframe frameBorder={0} src="https://bookmygarage.com/widget/70617868aa014bf18405490271df990b/" />
          </div>
          <div className="columns is-vcentered is-variable is-8">
            <div className="column is-half">
              <iframe title="Unit B5, Acre Road Business Park, Reading, Berkshire" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9951.465162530334!2d-0.9771845693437744!3d51.42388278132335!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48769d9624586a31%3A0x2ae5e0ad643d3ffa!2sDH%20Mechanics!5e0!3m2!1sen!2suk!4v1642426309155!5m2!1sen!2suk" style={{ border: '0', maxWidth: '100%' }} width="600" height="400" allowFullScreen={false} loading="lazy"></iframe>
            </div>
            <div className="column is-half">
              <div className="content is-medium">
                <p><strong>Phone number:</strong>&nbsp;&nbsp;<a className="has-text-danger" href={"tel: " + phoneNumber}>{phoneNumber}</a></p>
                <hr />
                <p><strong>Address:</strong></p>
                <section className="section py-0">
                  <p className="mb-0">Unit B5</p>
                  <p className="mb-0">Acre Road Business Park</p>
                  <p className="mb-0">Reading</p>
                  <p className="mb-0">Berkshire</p>
                  <p className="mb-0">RG2 0SA</p>
                </section>
              </div>
            </div>
          </div>
          <br /><br />

          {
            emailSent && 
              <div className="notification is-success">
                <div className="content">
                  <h3 className="title mb-2">Thank you for contacting us.</h3>
                  <p>We have received your enquiry and will respond as soon as possible. For urgent enquiries please call us on <a href={"tel: " + phoneNumber}>{phoneNumber}</a>.</p>
                </div>
              </div>
          }
          
          {
            emailFailed && 
              <div className="notification is-danger">
                <div className="content">
                  <h3 className="title mb-2">Somethings gone wrong sending your enquiry.</h3>
                  <p>Please call us on <a href={"tel: " + phoneNumber}>{phoneNumber}</a>.</p>
                </div>
              </div>
          }

          <form onSubmit={handleSubmit(submitContact)}>
            <div className="field">
              <label className="label">Name *</label>
              <div className="control">
                <input className="input" type="text" placeholder="Name" {...register('name', { required: true })} />
                {errors.name?.type === 'required' && <p className="help is-danger">Name is required.</p>}
              </div>
            </div>

            <div className="field">
              <label className="label">Email *</label>
              <div className="control">
                <input className="input" type="email" placeholder="@email.com" {...register('email', { required: true })} />
              </div>
              {errors.email?.type === 'required' && <p className="help is-danger">Email is required.</p>}
            </div>

            <div className="field">
              <label className="label">Phone number</label>
              <div className="control">
                <input className="input" type="number" placeholder="+44..." {...register('phone')} />
              </div>
            </div>
            
            <div className="field">
              <label className="label">Vehicle Registration</label>
              <div className="control">
                <input className="input" type="text" placeholder="RF56..." {...register('reg')} />
              </div>
            </div>

            <div className="field">
              <label className="label">Enquiry *</label>
              <div className="control">
                <textarea className="textarea" placeholder="Message" {...register('message', { required: true })}></textarea>
                {errors.message?.type === 'required' && <p className="help is-danger">Message is required.</p>}
              </div>
            </div>

            <Recaptcha
              sitekey="6Ldi084cAAAAACfhX0LSRfyWVGvydLVTBrcJeXr6"
              render="explicit"
              verifyCallback={(code) => recaptchaCallback(code)}
            />
            {showCaptchaError === true && <p className="help is-danger">Please verify that you are not a robot.</p>}

            <div className="field is-grouped mt-4">
              <div className="control">
                <input type="submit" className="button is-danger" value="Send" />
              </div>
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}

export default Contact;
