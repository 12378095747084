import { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from './images/dh-mechanics.png';

function Nav() {
  const [showMobileNav, toggleMobileNav] = useState(false);

  const toggleNav = () => {
    toggleMobileNav(!showMobileNav)
  }

  return (
    <nav className="navbar is-white">
      <div className="container px-3">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img src={logo} alt="DH Mechanics - Auto Repair &amp; Servicing" />
          </Link>
          <span role="button" className={"navbar-burger " + (showMobileNav && "is-active")} data-target="navMenu" aria-label="menu" aria-expanded="false" onClick={ () => toggleNav() }>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </span>
        </div>

        <div className={"navbar-menu " + (showMobileNav && "is-active")} id="navMenu">
          <div className="navbar-end">
            <div className="navbar-start">
              <NavLink className="navbar-item" activeClassName="is-active" exact to="/">Home</NavLink>
              <NavLink className="navbar-item" activeClassName="is-active" to="/services">Services</NavLink>
              <NavLink className="navbar-item is-hidden-desktop" activeClassName="is-active" to="/contact">Contact</NavLink>
              <NavLink className="navbar-item is-hidden-touch" activeClassName="is-active" to="/contact">
                <span className="button is-danger">Contact</span>
              </NavLink>
              <a className="navbar-item is-hidden-desktop text-primary" href="tel: 07793011683">Call us: 07793011683</a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Nav;
