import { Link } from 'react-router-dom';
import hero from './images/dh-mechanics-hero.jpg';
import springIcon from './images/spring-icon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCompactDisc, faRing, faTabletAlt, faToolbox, faWrench } from '@fortawesome/free-solid-svg-icons'
import { Helmet } from 'react-helmet';

function Homepage() {
  return (
    <div>
      <Helmet>
        <title>DH Mechanics</title>
      </Helmet>

      <section className="hero homepage-hero is-white is-halfheight is-medium-mobile">
        <div className="hero-body" style={{ backgroundImage: `url(${hero})` }}>
        </div>
      </section>
      <div className="container">
        <section className="section">
          <div className="content">
            <div className="content has-text-centered mb-6">
              <h1 className="title is-1">
                DH Mechanics
              </h1>
              <h2 className="subtitle is-4 has-text-danger">
                Auto Repair &amp; Servicing
              </h2>
            </div>

            <br />

            <div className="columns is-variable is-8">
              <div className="column is-half">
                <h3>About Us:</h3>
                <p>At DH Mechanics, we pride ourselves on versatility offering a wide range of services for all vehicle types, makes and models. As an independent mechanic with years of industry experience, we strive to offer our services in a cost-effective and timely manner, working to your needs. Whether you require a simple routine oil change, gearbox replacement or anything in between, we have you covered. Check out some of the services we provide.</p>
              </div>
              <div className="column is-half">
                <div className='iframe-container'>
                  <iframe frameBorder={0} src="https://bookmygarage.com/widget/70617868aa014bf18405490271df990b/" />
                </div>
              </div>
            </div>

            <br />


            <div className="columns service-columns is-vcentered is-centered is-multiline has-text-centered">
              <div className="column is-one-third">
                <div className="box">
                  <span className="icon is-large">
                    <FontAwesomeIcon icon={ faCompactDisc } style={{ height: '3em', width: '3em', transform: 'rotate(115deg)' }} />
                  </span>
                  <h3 className="title is-4 mt-2">Brakes</h3>
                  <hr />
                  <p>The braking system is among the most important components of your vehicle.</p>
                  <p>It is essential to keep your brakes in good working order to provide reliable control.</p>
                  <p><Link to="/services">Read more...</Link></p>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="box">
                  <span className="icon is-large">
                    <FontAwesomeIcon icon={ faRing } style={{ height: '3em', width: '3em' }} />
                  </span>
                  <h3 className="title is-4 mt-2">Tyres</h3>
                  <hr />
                  <p>It is vital to ensure your vehicle's tyres are in good condition and meet the road regulations.</p>
                  <p>Failure to replace unsuitable tyres can result in MOT failure, fines and points on your license</p>
                  <p><Link to="/services">Read more...</Link></p>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="box">
                  <span className="icon is-large">
                    <FontAwesomeIcon icon={ faWrench } style={{ height: '3em', width: '3em' }} />
                  </span>
                  <h3 className="title is-4 mt-2">Servicing</h3>
                  <hr />
                  <p>Routinely servicing your vehicle can prolong its life, reduce the risk of breakdown and help to maintain its value.</p>
                  <p>We can provide a vast selection of service options.</p>
                  <p><Link to="/services">Read more...</Link></p>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="box">
                  <span className="icon is-large">
                    <FontAwesomeIcon icon={ faTabletAlt } style={{ height: '3em', width: '3em' }} />
                  </span>
                  <h3 className="title is-4 mt-2">Diagnostics</h3>
                  <hr />
                  <p>If your vehicle is experiencing intermittent faults, showing warning lights on the dash or driving in limp mode, it will require a diagnostic test.</p>
                  <p><Link to="/services">Read more...</Link></p>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="box">
                  <span className="icon is-large">
                    {/* <FontAwesomeIcon icon={ faTabletAlt } style={{ height: '3em', width: '3em' }} /> */}
                    <img src={springIcon} style={{ height: '3em', width: '3em' }} /> 
                  </span>
                  <h3 className="title is-4 mt-2">Suspension</h3>
                  <hr />
                  <p>The various suspension components on a vehicle work in conjunction to provide the driver with a controlled and comfortable driving experience.</p>
                  <p><Link to="/services">Read more...</Link></p>
                </div>
              </div>
              <div className="column is-one-third">
                <div className="box">
                  <span className="icon is-large">
                    <FontAwesomeIcon icon={ faToolbox } style={{ height: '3em', width: '3em' }} />
                  </span>
                  <h3 className="title is-4 mt-2">Aftermarket Parts</h3>
                  <hr />
                  <p>At DH Mechanics our expertise also extends to modified vehicles. We specialise in the fitting and testing of aftermarket parts.</p>
                  <p><Link to="/services">Read more...</Link></p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Homepage;
